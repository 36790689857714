import React, { useState, useEffect } from 'react';
import './LibrisArchiveSpells.css';

const LibrisArchiveSpells = ({ currentUser, API_URL }) => {
  // Spell-related state
  const [spellLibrary, setSpellLibrary] = useState([]);
  const [filteredSpells, setFilteredSpells] = useState([]);
  const [spellSearch, setSpellSearch] = useState('');
  const [expandedSpells, setExpandedSpells] = useState({});
  const [favoriteSpells, setFavoriteSpells] = useState([]);
  const [preparedSpells, setPreparedSpells] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    favorites: false,
    prepared: false,
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], // Default all levels active
    school: []
  });
  const [filterMenuOpen, setFilterMenuOpen] = useState(null);
  const [spellSourceBooks, setSpellSourceBooks] = useState([]);
  const [activeSourceBooks, setActiveSourceBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [activeClass, setActiveClass] = useState(null);
  const [classSpells, setClassSpells] = useState({
    artificer: [],
    cleric: [],
    druid: [],
    paladin: [],
    ranger: [],
    sorcerer: [],
    warlock: [],
    wizard: []
  });

  // Load spells on component mount
  useEffect(() => {
    initializeSpellData();
  }, []);
  
  // Load class-specific spells whenever the spell library changes
  useEffect(() => {
    if (spellLibrary.length > 0) {
      loadClassSpells();
    }
  }, [spellLibrary]);

  // Load class spell lists
  const loadClassSpells = async () => {
    try {
      console.log("Loading class-specific spell lists...");
      
      // Load artificer spells
      try {
        console.log("Attempting to load artificer spells from artificer-spells.json");
        const artificerModule = await import('../data/spells/artificer-spells.json');
        
        if (artificerModule && artificerModule.default) {
          const artificerSpellNames = artificerModule.default.map(spell => spell.name);
          setClassSpells(prev => ({
            ...prev,
            artificer: artificerSpellNames
          }));
          console.log(`Successfully loaded ${artificerSpellNames.length} artificer spells`);
        } else {
          console.error("Artificer spells module loaded but spells array is missing");
        }
      } catch (artificerError) {
        console.error("Error loading artificer spells:", artificerError);
        
        // Fallback to loading from other spell files
        console.log("Falling back to parsing from general spell files");
        try {
          const spellsArtificer = [];
          
          // Get artificer spells from the main spell library
          spellLibrary.filter(spell => {
            // Filter based on class information if available
            return spell.classes?.includes('artificer') || 
                  (spell.source === 'TCE' && ['Abj.', 'Trans.', 'Evoc.'].includes(spell.school));
          }).forEach(spell => {
            spellsArtificer.push(spell.name);
          });
          
          setClassSpells(prev => ({
            ...prev,
            artificer: spellsArtificer
          }));
          
          console.log(`Loaded ${spellsArtificer.length} artificer spells through fallback method`);
        } catch (fallbackError) {
          console.error("Fallback loading for artificer spells also failed:", fallbackError);
        }
      }
      
      // Load cleric spells
      try {
        console.log("Attempting to load cleric spells from cleric-spells.json");
        const clericModule = await import('../data/spells/cleric-spells.json');
        
        if (clericModule && clericModule.default) {
          const clericSpellNames = clericModule.default.map(spell => spell.name);
          setClassSpells(prev => ({
            ...prev,
            cleric: clericSpellNames
          }));
          console.log(`Successfully loaded ${clericSpellNames.length} cleric spells`);
        } else {
          console.error("Cleric spells module loaded but spells array is missing");
        }
      } catch (clericError) {
        console.error("Error loading cleric spells:", clericError);
      }
      
      // Load druid spells
      try {
        console.log("Attempting to load druid spells from druid-spells.json");
        const druidModule = await import('../data/spells/druid-spells.json');
        
        if (druidModule && druidModule.spells) {
          const druidSpellNames = druidModule.spells.map(spell => spell.name);
          setClassSpells(prev => ({
            ...prev,
            druid: druidSpellNames
          }));
          console.log(`Successfully loaded ${druidSpellNames.length} druid spells`);
        } else {
          console.error("Druid spells module loaded but spells array is missing");
        }
      } catch (druidError) {
        console.error("Error loading druid spells:", druidError);
        
        // Fallback to loading from other spell files
        console.log("Falling back to parsing from general spell files");
        try {
          const spellsDruid = [];
          
          // Get druid spells from the main spell library
          spellLibrary.filter(spell => {
            // Filter based on class information if available
            return spell.classes?.includes('druid') || 
                  (spell.source === 'XGE' && ['Trans.', 'Conj.', 'Evoc.'].includes(spell.school));
          }).forEach(spell => {
            spellsDruid.push(spell.name);
          });
          
          setClassSpells(prev => ({
            ...prev,
            druid: spellsDruid
          }));
          
          console.log(`Loaded ${spellsDruid.length} druid spells through fallback method`);
        } catch (fallbackError) {
          console.error("Fallback loading for druid spells also failed:", fallbackError);
        }
      }
      
      // Load paladin spells
      try {
        console.log("Attempting to load paladin spells from paladin-spells.json");
        const paladinModule = await import('../data/spells/paladin-spells.json');
        
        if (paladinModule && paladinModule.default) {
          const paladinSpellNames = paladinModule.default.map(spell => spell.name);
          setClassSpells(prev => ({
            ...prev,
            paladin: paladinSpellNames
          }));
          console.log(`Successfully loaded ${paladinSpellNames.length} paladin spells`);
        } else {
          console.error("Paladin spells module loaded but spells array is missing");
        }
      } catch (paladinError) {
        console.error("Error loading paladin spells:", paladinError);
      }
      
      // Load ranger spells
      try {
        console.log("Attempting to load ranger spells from ranger-spells.json");
        const rangerModule = await import('../data/spells/ranger-spells.json');
        
        if (rangerModule && rangerModule.default) {
          const rangerSpellNames = rangerModule.default.map(spell => spell.name);
          setClassSpells(prev => ({
            ...prev,
            ranger: rangerSpellNames
          }));
          console.log(`Successfully loaded ${rangerSpellNames.length} ranger spells`);
        } else {
          console.error("Ranger spells module loaded but spells array is missing");
        }
      } catch (rangerError) {
        console.error("Error loading ranger spells:", rangerError);
      }
      
      // Load sorcerer spells
      try {
        console.log("Attempting to load sorcerer spells from sorcerer-spells.json");
        const sorcererModule = await import('../data/spells/sorcerer-spells.json');
        
        if (sorcererModule && sorcererModule.default) {
          const sorcererSpellNames = sorcererModule.default.map(spell => spell.name);
          setClassSpells(prev => ({
            ...prev,
            sorcerer: sorcererSpellNames
          }));
          console.log(`Successfully loaded ${sorcererSpellNames.length} sorcerer spells`);
        } else {
          console.error("Sorcerer spells module loaded but spells array is missing");
        }
      } catch (sorcererError) {
        console.error("Error loading sorcerer spells:", sorcererError);
      }
      
      // Load warlock spells
      try {
        console.log("Attempting to load warlock spells from warlock-spells.json");
        const warlockModule = await import('../data/spells/warlock-spells.json');
        
        if (warlockModule && warlockModule.default) {
          const warlockSpellNames = warlockModule.default.map(spell => spell.name);
          setClassSpells(prev => ({
            ...prev,
            warlock: warlockSpellNames
          }));
          console.log(`Successfully loaded ${warlockSpellNames.length} warlock spells`);
        } else {
          console.error("Warlock spells module loaded but spells array is missing");
        }
      } catch (warlockError) {
        console.error("Error loading warlock spells:", warlockError);
      }
      
      // Load wizard spells
      try {
        console.log("Attempting to load wizard spells from wizard-spells.json");
        const wizardModule = await import('../data/spells/wizard-spells.json');
        
        if (wizardModule && wizardModule.default) {
          const wizardSpellNames = wizardModule.default.map(spell => spell.name);
          setClassSpells(prev => ({
            ...prev,
            wizard: wizardSpellNames
          }));
          console.log(`Successfully loaded ${wizardSpellNames.length} wizard spells`);
        } else {
          console.error("Wizard spells module loaded but spells array is missing");
        }
      } catch (wizardError) {
        console.error("Error loading wizard spells:", wizardError);
      }
    } catch (error) {
      console.error("Error in loadClassSpells function:", error);
    }
  };

  // Initialize spell data function
  const initializeSpellData = async () => {
    try {
      console.log("Loading all spells from JSON files...");
      setLoading(true);
      
      // Load all spells from JSON files
      let allSpells = [];
      let sourceBooks = new Set();
      
      // Directly use the specific spell file names
      const spellFiles = [
        'spells-phb.json',
        'spells-tce.json',
        'spells-xge.json',
        'spells-aag.json',
        'spells-ai.json',
        'spells-aitfr-avt.json',
        'spells-bgg.json',
        'spells-boek.json',
        'spells-egw.json',
        'spells-ftd.json',
        'spells-ggr.json',
        'spells-ghloe.json',
        'spells-idrotf.json',
        'spells-idrF.json',
        'spells-sato.json',
        'spells-sck.json',
        'spells-tdsbw.json',
        'spells-xche.json',
        'spells-xphb.json'
      ];
      
      // Load each spell file
      for (const filename of spellFiles) {
        try {
          console.log(`Loading ${filename}...`);
          const module = await import(`../data/spells/${filename}`);
          const source = filename.replace('spells-', '').replace('.json', '').toUpperCase();
          
          // Don't include PHB in active sources
          if (source !== 'PHB') {
            sourceBooks.add(source);
          }
          
          if (module && module.spell) {
            if (Array.isArray(module.spell)) {
              // Handle arrays of spells
              const spellsWithSource = module.spell.map(spell => ({
                ...spell,
                source: source,
                sourceBook: source
              }));
              allSpells = [...allSpells, ...spellsWithSource];
            } else {
              // Handle single spell objects
              allSpells.push({
                ...module.spell,
                source: source,
                sourceBook: source
              });
            }
          }
        } catch (error) {
          console.error(`Error loading ${filename}:`, error);
        }
      }
      
      console.log(`Loaded ${allSpells.length} spells from ${sourceBooks.size} source books`);
      
      // Update all the necessary state
      setSpellSourceBooks([...sourceBooks]);
      setActiveSourceBooks([...sourceBooks]); // Enable all sources except PHB
      setSpellLibrary(allSpells);
      setFilteredSpells(allSpells);
      
      // Fetch prepared spells and favorites from the server
      fetchPreparedSpells();
      fetchFavoriteSpells();
    } catch (error) {
      console.error("Error initializing spell data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Toggle spell expansion
  const toggleSpellExpand = (spellId) => {
    setExpandedSpells(prev => ({
      ...prev,
      [spellId]: !prev[spellId]
    }));
  };

  // Helper function to check if a spell is in favorites
  const isSpellFavorite = (spell) => {
    // Generate a unique identifier for the spell
    const spellId = spell.id || spell._id || `${spell.name}-${spell.source || spell.sourceBook}`;
    
    // Check if this spell is in favorites
    return favoriteSpells.some(favSpell => {
      const favId = favSpell.id || favSpell._id || `${favSpell.name}-${favSpell.source || favSpell.sourceBook}`;
      return favId === spellId;
    });
  };

  // Function to add or remove a spell from favorites
  const handleAddToFavorites = async (spell) => {
    // Generate a unique identifier for the spell
    const spellId = spell.id || spell._id || `${spell.name}-${spell.source || spell.sourceBook}`;
    console.log('Adding/removing spell from favorites:', spell.name, 'with ID:', spellId);
    
    // Update the local state for immediate feedback
    let newFavoriteSpells;
    if (isSpellFavorite(spell)) {
      console.log('Removing spell from favorites');
      // Remove from favorites
      newFavoriteSpells = favoriteSpells.filter(favSpell => {
        const favId = favSpell.id || favSpell._id || `${favSpell.name}-${favSpell.source || favSpell.sourceBook}`;
        return favId !== spellId;
      });
    } else {
      console.log('Adding spell to favorites');
      // Add to favorites
      newFavoriteSpells = [...favoriteSpells, spell];
    }
    
    // Update the UI immediately
    setFavoriteSpells(newFavoriteSpells);
    
    try {
      // Send the updated favorite spells to the server
      console.log('Sending favorite spells to server...');
      const response = await fetch(`${API_URL}/character-data/spell-favorites`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify(newFavoriteSpells)
      });
      
      if (!response.ok) {
        console.error('Server responded with status:', response.status);
        throw new Error(`Failed to update favorite spells: ${response.statusText}`);
      }
      
      console.log('Favorite spells saved successfully to user profile');
      
    } catch (error) {
      console.error('Error saving favorite spells:', error);
      alert(`Failed to save favorite spells: ${error.message}`);
    }
  };

  // Function to check if a spell is prepared
  const isSpellPrepared = (spell) => {
    // Generate a unique identifier for the spell
    const spellId = spell.id || spell._id || `${spell.name}-${spell.source || spell.sourceBook}`;
    
    // Check if this spell is in prepared spells
    return preparedSpells.some(prepSpell => {
      const prepId = prepSpell.id || prepSpell._id || `${prepSpell.name}-${prepSpell.source || prepSpell.sourceBook}`;
      return prepId === spellId;
    });
  };
  
  // Function to prepare or unprepare a spell
  const handlePrepareSpell = async (spell) => {
    // Generate a unique identifier for the spell
    const spellId = spell.id || spell._id || `${spell.name}-${spell.source || spell.sourceBook}`;
    console.log('Preparing/unpreparing spell:', spell.name, 'with ID:', spellId);
    
    // Update the local state for immediate feedback
    let newPreparedSpells;
    if (isSpellPrepared(spell)) {
      console.log('Removing spell from prepared spells');
      // Remove from prepared spells
      newPreparedSpells = preparedSpells.filter(prepSpell => {
        const prepId = prepSpell.id || prepSpell._id || `${prepSpell.name}-${prepSpell.source || prepSpell.sourceBook}`;
        return prepId !== spellId;
      });
    } else {
      console.log('Adding spell to prepared spells');
      // Add to prepared spells
      newPreparedSpells = [...preparedSpells, spell];
    }
    
    // Update the UI immediately
    setPreparedSpells(newPreparedSpells);
    
    try {
      // Send the updated prepared spells to the server
      console.log('Sending prepared spells to server...');
      const response = await fetch(`${API_URL}/character-data/prepared-spells`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify(newPreparedSpells)
      });
      
      if (!response.ok) {
        console.error('Server responded with status:', response.status);
        throw new Error(`Failed to update prepared spells: ${response.statusText}`);
      }
      
      console.log('Prepared spells saved successfully to user profile');
      
    } catch (error) {
      console.error('Error saving prepared spells:', error);
      alert(`Failed to save prepared spells: ${error.message}`);
    }
  };
  
  // Function to load prepared spells from the server
  const fetchPreparedSpells = async () => {
    try {
      console.log('Fetching prepared spells from server');
      const response = await fetch(`${API_URL}/character-data/prepared-spells`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        console.error('Server responded with status:', response.status);
        throw new Error(`Failed to fetch prepared spells: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Prepared spells loaded from user profile:', data?.length || 0, 'spells');
      
      if (Array.isArray(data)) {
        setPreparedSpells(data);
      } else {
        console.warn('Server returned invalid data format', data);
        setPreparedSpells([]);
      }
    } catch (error) {
      console.error('Error fetching prepared spells:', error);
      setPreparedSpells([]);
    }
  };

  // Function to load favorite spells from the server
  const fetchFavoriteSpells = async () => {
    try {
      console.log('Fetching favorite spells from server');
      const response = await fetch(`${API_URL}/character-data/spell-favorites`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        console.error('Server responded with status:', response.status);
        throw new Error(`Failed to fetch favorite spells: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Favorite spells loaded from user profile:', data?.length || 0, 'spells');
      
      if (Array.isArray(data)) {
        setFavoriteSpells(data);
      } else {
        console.warn('Server returned invalid data format for favorites', data);
        setFavoriteSpells([]);
      }
    } catch (error) {
      console.error('Error fetching favorite spells:', error);
      setFavoriteSpells([]);
    }
  };

  // Helper functions for formatting spell data
  const formatSchool = (spell) => {
    const school = spell.school || spell.system?.school || '';
    if (school.toLowerCase() === 'conjuration') return 'Conjur.';
    if (school.toLowerCase() === 'evocation') return 'Evoc.';
    if (school.toLowerCase() === 'abjuration') return 'Abjur.';
    if (school.toLowerCase() === 'transmutation') return 'Trans.';
    if (school.toLowerCase() === 'divination') return 'Divin.';
    if (school.toLowerCase() === 'enchantment') return 'Ench.';
    if (school.toLowerCase() === 'illusion') return 'Illus.';
    if (school.toLowerCase() === 'necromancy') return 'Necro.';
    return school;
  };

  // Format casting time display
  const formatCastingTime = (spell) => {
    if (spell.time && spell.time.length > 0) {
      return `${spell.time[0]?.number || 1} ${spell.time[0]?.unit || 'action'}`;
    }
    return spell.system?.activation?.type || '1 action';
  };

  // Format range display
  const formatRange = (spell) => {
    if (spell.range) {
      return `${spell.range.distance?.amount || ''} ${spell.range.distance?.type || ''}`.trim() || 'Self';
    }
    return spell.system?.range?.value || spell.system?.range || 'Self';
  };

  // Format components display
  const formatComponents = (spell) => {
    if (typeof spell.components === 'object') {
      const componentList = [];
      if (spell.components.v) componentList.push('V');
      if (spell.components.s) componentList.push('S');
      if (spell.components.m) componentList.push('M');
      return componentList.join(', ');
    }
    return spell.components || 'V, S';
  };

  // Format duration display
  const formatDuration = (spell) => {
    if (spell.duration && spell.duration.length > 0) {
      return `${spell.duration[0].concentration ? 'Concentration, ' : ''}${spell.duration[0].value || ''} ${spell.duration[0].type || 'rounds'}`.trim() || 'Instantaneous';
    }
    return spell.system?.duration?.value || 'Instantaneous';
  };

  // Get spell description
  const getSpellDescription = (spell) => {
    if (typeof spell.description === 'string' && spell.description.trim()) {
      return (
        <>
          {spell.description.split('\n\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </>
      );
    } else if (typeof spell.description === 'object') {
      return JSON.stringify(spell.description);
    } else if (spell.system?.description?.value) {
      if (typeof spell.system.description.value === 'string') {
        return (
          <>
            {spell.system.description.value.split('\n\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </>
        );
      } else {
        return JSON.stringify(spell.system.description.value);
      }
    }
    
    return (
      <>
        <p>You hurl a bubble of acid. Choose one creature you can see within range, or choose two creatures you can see within range that are within 5 feet of each other. A target must succeed on a Dexterity saving throw or take 1d6 acid damage.</p>
        <p>This spell's damage increases by 1d6 when you reach 5th level (2d6), 11th level (3d6), and 17th level (4d6).</p>
      </>
    );
  };

  // Toggle level filter
  const toggleLevelFilter = (level) => {
    console.log("Toggle level filter:", level);
    
    setActiveFilters(prev => {
      const currentLevels = [...prev.level];
      const newLevels = currentLevels.includes(level)
        ? currentLevels.filter(l => l !== level)
        : [...currentLevels, level];
      
      console.log("New level filters:", newLevels);
      
      return {
        ...prev,
        level: newLevels
      };
    });
  };

  // Toggle class filter
  const toggleClassFilter = (className) => {
    setActiveClass(prev => prev === className ? null : className);
  };

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    
    // Reset filters when changing tabs
    if (tab === 'favorites') {
      setActiveFilters(prev => ({
        ...prev,
        favorites: true,
        prepared: false
      }));
    } else if (tab === 'prepared') {
      setActiveFilters(prev => ({
        ...prev,
        favorites: false,
        prepared: true
      }));
    } else {
      setActiveFilters(prev => ({
        ...prev,
        favorites: false,
        prepared: false
      }));
    }
  };

  return (
    <div className="libris-archive-container">
      <div className="tabs-container">
        <div className="spell-tabs">
          <button 
            className={`tab-button ${activeTab === 'all' ? 'active' : ''}`}
            onClick={() => handleTabChange('all')}
          >
            All Spells
          </button>
          <button 
            className={`tab-button ${activeTab === 'favorites' ? 'active' : ''}`}
            onClick={() => handleTabChange('favorites')}
          >
            Favorites
          </button>
          <button 
            className={`tab-button ${activeTab === 'prepared' ? 'active' : ''}`}
            onClick={() => handleTabChange('prepared')}
          >
            Prepared
          </button>
        </div>
      </div>

      <div className="search-section">
        <input 
          type="text" 
          className="search-input" 
          placeholder="» Search spells..." 
          value={spellSearch}
          onChange={(e) => setSpellSearch(e.target.value)}
        />
      </div>
      
      <div className="filters-section">
        <div className="filter-section class-filters">
          <h3>Class</h3>
          <div className="class-buttons">
            <button
              className={`class-btn ${activeClass === 'artificer' ? 'active' : ''}`}
              onClick={() => toggleClassFilter('artificer')}
            >
              Artificer
            </button>
            <button
              className={`class-btn ${activeClass === 'cleric' ? 'active' : ''}`}
              onClick={() => toggleClassFilter('cleric')}
            >
              Cleric
            </button>
            <button
              className={`class-btn ${activeClass === 'druid' ? 'active' : ''}`}
              onClick={() => toggleClassFilter('druid')}
            >
              Druid
            </button>
            <button
              className={`class-btn ${activeClass === 'paladin' ? 'active' : ''}`}
              onClick={() => toggleClassFilter('paladin')}
            >
              Paladin
            </button>
            <button
              className={`class-btn ${activeClass === 'ranger' ? 'active' : ''}`}
              onClick={() => toggleClassFilter('ranger')}
            >
              Ranger
            </button>
            <button
              className={`class-btn ${activeClass === 'sorcerer' ? 'active' : ''}`}
              onClick={() => toggleClassFilter('sorcerer')}
            >
              Sorcerer
            </button>
            <button
              className={`class-btn ${activeClass === 'warlock' ? 'active' : ''}`}
              onClick={() => toggleClassFilter('warlock')}
            >
              Warlock
            </button>
            <button
              className={`class-btn ${activeClass === 'wizard' ? 'active' : ''}`}
              onClick={() => toggleClassFilter('wizard')}
            >
              Wizard
            </button>
          </div>
        </div>
        
        <div className="filter-section level-filters">
          <h3>Level</h3>
          <div className="level-buttons">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(level => (
              <button
                key={level}
                className={`level-btn ${activeFilters.level.includes(level) ? 'active' : ''}`}
                onClick={() => toggleLevelFilter(level)}
                style={{
                  backgroundColor: activeFilters.level.includes(level) ? '#1aff00' : 'transparent',
                  color: activeFilters.level.includes(level) ? '#000000' : '#1aff00'
                }}
              >
                {level === 0 ? 'C' : level}
              </button>
            ))}
          </div>
        </div>
      </div>
      
      <div className="spell-table-container">
        <table className="spell-table">
          <thead>
            <tr>
              <th></th>
              <th>NAME</th>
              <th>LEVEL</th>
              <th className="actions-header">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {spellLibrary
              .filter(spell => {
                // Apply search filter
                if (spellSearch && !spell.name.toLowerCase().includes(spellSearch.toLowerCase())) {
                  return false;
                }
                
                // Apply favorite filter
                if (activeFilters.favorites && !isSpellFavorite(spell)) {
                  return false;
                }
                
                // Apply prepared filter
                if (activeFilters.prepared && !isSpellPrepared(spell)) {
                  return false;
                }
                
                // Apply source filter (exclude PHB)
                const spellSource = spell.source || spell.sourceBook;
                if (spellSource === 'PHB') {
                  return false;
                }
                
                // Apply level filter
                if (activeFilters.level.length > 0) {
                  const level = parseInt(spell.level || 0);
                  if (!activeFilters.level.includes(level)) {
                    return false;
                  }
                }
                
                // Apply class filter
                if (
                  (activeClass === 'artificer' && !classSpells.artificer.includes(spell.name)) ||
                  (activeClass === 'cleric' && !classSpells.cleric.includes(spell.name)) ||
                  (activeClass === 'druid' && !classSpells.druid.includes(spell.name)) ||
                  (activeClass === 'paladin' && !classSpells.paladin.includes(spell.name)) ||
                  (activeClass === 'ranger' && !classSpells.ranger.includes(spell.name)) ||
                  (activeClass === 'sorcerer' && !classSpells.sorcerer.includes(spell.name)) ||
                  (activeClass === 'warlock' && !classSpells.warlock.includes(spell.name)) ||
                  (activeClass === 'wizard' && !classSpells.wizard.includes(spell.name))
                ) {
                  return false;
                }
                
                // Apply school filter
                if (activeFilters.school.length > 0) {
                  const school = spell.school || '';
                  if (!activeFilters.school.includes(school)) {
                    return false;
                  }
                }
                
                return true;
              })
              .sort((a, b) => {
                // Sort by level first, then name
                const levelA = parseInt(a.level || 0);
                const levelB = parseInt(b.level || 0);
                if (levelA !== levelB) return levelA - levelB;
                return a.name.localeCompare(b.name);
              })
              .map(spell => {
                const spellId = spell.id || `${spell.name}-${spell.source || spell.sourceBook}`.replace(/\s+/g, '-');
                const isExpanded = expandedSpells[spellId] || false;
                const level = parseInt(spell.level || 0);
                
                return (
                  <React.Fragment key={spellId}>
                    <tr className={`spell-row ${isExpanded ? 'expanded' : ''}`} onClick={() => toggleSpellExpand(spellId)}>
                      <td className="expand-cell">
                        <span className="expand-icon">{isExpanded ? '-' : '+'}</span>
                      </td>
                      <td className="name-cell">
                        <span className="spell-icon damage-acid"></span>
                        {spell.name}
                        
                        {isExpanded && (
                          <div className="spell-accordion-details">
                            <div className="accordion-header">
                              <div className="spell-properties">
                                <div className="spell-property">
                                  <span className="property-label">» LEVEL</span>
                                  <span className="property-value">{level === 0 ? 'Cantrip' : level}</span>
                                </div>
                                <div className="spell-property">
                                  <span className="property-label">» SCHOOL</span>
                                  <span className="property-value">{spell.school}</span>
                                </div>
                                <div className="spell-property">
                                  <span className="property-label">» TIME</span>
                                  <span className="property-value">{formatCastingTime(spell)}</span>
                                </div>
                                <div className="spell-property">
                                  <span className="property-label">» RANGE</span>
                                  <span className="property-value">{formatRange(spell)}</span>
                                </div>
                                <div className="spell-property">
                                  <span className="property-label">» COMPONENTS</span>
                                  <span className="property-value">{formatComponents(spell)}</span>
                                </div>
                                <div className="spell-property">
                                  <span className="property-label">» DURATION</span>
                                  <span className="property-value">{formatDuration(spell)}</span>
                                </div>
                                <div className="spell-property">
                                  <span className="property-label">» SOURCE</span>
                                  <span className="property-value">{spell.source || spell.sourceBook}</span>
                                </div>
                              </div>
                              <div className="accordion-buttons">
                                <button 
                                  className={`spell-action-button ${isSpellPrepared(spell) ? 'is-prepared' : ''}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handlePrepareSpell(spell);
                                  }}
                                >
                                  {isSpellPrepared(spell) ? 'Unprepare' : 'Prepare'}
                                </button>
                                <button 
                                  className={`spell-action-button ${isSpellFavorite(spell) ? 'is-favorite' : ''}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleAddToFavorites(spell);
                                  }}
                                >
                                  {isSpellFavorite(spell) ? 'Unfavorite' : 'Favorite'}
                                </button>
                              </div>
                            </div>
                            <div className="spell-description">
                              {getSpellDescription(spell)}
                            </div>
                          </div>
                        )}
                      </td>
                      <td className="level-cell">{level === 0 ? 'C' : level}</td>
                      <td className="actions-cell">
                        <div className="action-buttons">
                          <button 
                            className="action-btn favorite-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddToFavorites(spell);
                            }}
                            title={isSpellFavorite(spell) ? "Remove from Favorites" : "Add to Favorites"}
                          >
                            {isSpellFavorite(spell) ? '★' : '☆'}
                          </button>
                          <button 
                            className="action-btn prepare-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePrepareSpell(spell);
                            }}
                            title={isSpellPrepared(spell) ? "Unprepare Spell" : "Prepare Spell"}
                          >
                            {isSpellPrepared(spell) ? '📜' : '📃'}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LibrisArchiveSpells; 